import {Box, Dialog, DialogContent, Grid, InputAdornment, TextField, Typography,} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import MenuItem from "@mui/material/MenuItem";
import {CheckCircle, Error} from "@mui/icons-material";
import {useEffect, useState} from "react";
import FormControl from "@mui/material/FormControl";
import Tooltip from '@mui/material/Tooltip';
import {makeStyles} from "@mui/styles";
import {useForm} from "react-hook-form";
import {StyledButtonContained, StyledButtonOutlined,} from "../Admin/AccountCreation/accountCreation";
import {getLoginUser} from "../../LocalStorageService";
import axios from "axios";
import {getApiUrl} from "./../../global";
import {_getFormattedProperty, isArrayOfStrings, isString} from "./restore";

const RestoreForm = ({
                         isFormOpen,
                         handleCloseFormDialog,
                         cloudShow,
                         physicalShow,
                         addedRows,
                         handleCloseCheckoutDialog,
                         handleClickcancel,
                         clearAddedRows,
                         showReset,
                         listFilter,
                         checkoutRestorePreferences
                     }) => {
    const [state, setState] = useState({
        open: false,
        vertical: "top",
        horizontal: "right",
    });
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const {vertical, horizontal, open} = state;
    const {register, handleSubmit} = useForm();
    const [companyName, setCompanyName] = useState("");
    const [contactName, setContactName] = useState("");
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(null);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [additionalInstructions, setAdditionalInstructions] = useState("");
    const [phoneNumberError, setPhoneNumberError] = useState(null);
    const [tapeVolsers, setTapeVolsers] = useState("");
    const [fileName, setFileName] = useState("");
    const [methodsAsString, setMethodsAsString] = useState("");
    const [urgencyLevel, setUrgencyLevel] = useState("low");
    const userDetails = getLoginUser();
    const openSnackbar = (message) => {
        setSnackbarMessage(message);
        setState({
            vertical: "top",
            horizontal: "right",
            open: true,
        });
    };

    const closeSnackbar = () => {
        setState({
            vertical: "top",
            horizontal: "right",
            open: false,
        });
    };
    // Validators function
    const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(email)) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
    };

    const E164_REGEX = /^\+?[1-9]\d{10,14}$/;
    const validatePhoneNumber = (phone) => {
        const formattedPhoneNumber = phone.replace(/\s/g, "");
        if (E164_REGEX.test(formattedPhoneNumber)) setPhoneNumberError(false)
        else setPhoneNumberError(true)

    };
    const useStyles = makeStyles({
        selectLabel: {
            "& .MuiOutlinedInput-root": {
                "& fieldset": {
                    borderRadius: "8px",
                    borderColor: "#C7CCD0",
                },
                "&:hover fieldset": {
                    borderColor: "#C7CCD0",
                },
                "&.Mui-focused fieldset": {
                    borderColor: "#C7CCD0",
                },
            },
            "& .MuiInputBase-root.Mui-disabled": {
                "& > fieldset": {
                    color: "rgba(255, 255, 255, 0.5)",
                    borderColor: "#C7CCD0",
                }
            },
            "& .MuiOutlinedInput-input": {
                color: "#FFFFFF",
            },
            "& .MuiSelect-root": {
                borderRadius: "8px",
                "& > fieldset": {
                    color: "rgba(255, 255, 255, 0.5)",
                },
                "& .Mui-disabled": {
                    color: "rgba(255, 255, 255, 0.5)",
                },
                "& .Mui-input": {
                    color: "rgba(255, 255, 255, 0.5)",
                },
            },
            "& .MuiInputLabel-root": {
                color: "rgba(255, 255, 255, 0.5)",
                "&.Mui-focused": {
                    color: "#FFC540",
                },
                "&.Mui-disabled": {
                    color: "rgba(255, 255, 255, 0.5)",
                },
            },
            "& svg": {
                color: "#FFFFFF",
            },
        },
    });
    const classes = useStyles();

    const onSubmit = async (data) => {
        let restorePreferences = {}; //restorePreferences = {"Cloud Restore": ["I want to have a signed URL for my files"], }
        Object.values(checkoutRestorePreferences).forEach(preferenceDetails => {
            if (!restorePreferences[preferenceDetails.type]) restorePreferences[preferenceDetails.type] = [];
            if (preferenceDetails.value) restorePreferences[preferenceDetails.type].push(preferenceDetails.text);
        });

        let restoreFileDetails = {}; // restoreFileDetails = {"volser": [{"file_id": "file_id", "path": "path"}, {"file_id": "file_id", "path": "path"}]}
        function _addFileDetails(volser, file_id, path) {
            if (!restoreFileDetails[volser]) restoreFileDetails[volser] = [];
            restoreFileDetails[volser].push({"file_id": file_id, "path": path});
        }

        if (listFilter == 'itemList') addedRows.forEach((addedItem) => {
            if (isString(addedItem?.volsers)) {
                _addFileDetails(addedItem.volsers, addedItem.file_id, addedItem.path);
            } else if (isArrayOfStrings(addedItem?.volsers)) {
                addedItem.volsers.forEach(volser => {
                    _addFileDetails(volser, addedItem.file_id, addedItem.path);
                });
            } else {
                console.error("Invalid volsers format", addedItem);
            }
        })

        let url = getApiUrl();
        let apiUrl =
            url + process.env.REACT_APP_API_BASE_URL + `/contact/`;
        let payload = {
            company_name: companyName,
            contact_name: contactName,
            email: email,
            phone_number: phoneNumber,
            contact_type: "restore",
            message: additionalInstructions,
            urgency: urgencyLevel,
            tape_ids: tapeVolsers,
            file_names: listFilter == 'itemList' ? fileName : undefined,
            method: methodsAsString,
            contact_by_phone: false,
            contact_by_email: false,
            contact_email: email,
            restore_preferences: restorePreferences,
            restore_file_details: listFilter == 'itemList' ? restoreFileDetails : undefined,
        };
        axios
            .post(apiUrl, payload)
            .then((response) => {
                if (response?.status == 201) {
                    openSnackbar("E-mail sent to Admin to create Restore.");
                    handleCloseFormDialog();

                    handleClickcancel();
                    handleCloseCheckoutDialog();
                    clearAddedRows();
                    showReset()
                }
            })
            .catch((error) => {
                console.log(error);
                setSnackbarMessage(error);
            });
    };

    useEffect(() => {
        setCompanyName(
            userDetails.user.company_name
        );
        setContactName(
            userDetails.user.first_name + " " + userDetails.user.last_name
        );
        setEmail(userDetails.user.email);
        validateEmail(userDetails.user.email);

        if (listFilter == 'itemList') {
            const concatenatedFileNames = addedRows?.map((selectedItem) => _getFormattedProperty(selectedItem, "name", "str")).join(",");
            setFileName(concatenatedFileNames);
        }

        let concatenatedVolsers = "";
        if (listFilter == 'itemList') concatenatedVolsers = addedRows?.map((selectedItem) => _getFormattedProperty(selectedItem, "volsers", "str"))
        else concatenatedVolsers = addedRows?.map((selectedItem) => _getFormattedProperty(selectedItem, "item_volser", "str"))
        concatenatedVolsers = [...new Set(concatenatedVolsers)];
        setTapeVolsers(concatenatedVolsers.join(","));

        let methods = [];
        if (physicalShow) {
            methods.push("Physical Restore");
        }
        if (cloudShow) {
            methods.push("Cloud Restore");
        }
        setMethodsAsString(methods.join(", "));
    }, [physicalShow, cloudShow, addedRows]);

    const handlePhoneNumberChange = (event) => {
        let newValue = event.target.value;
        if (/^[\d+\s]*$/.test(newValue)) {
            // ensure the first character is a +
            if (newValue.length === 1 && /^\d*$/.test(newValue)) {
                newValue = `+61 ${newValue}`;
            }

            let derivate1 = newValue.slice(0, -1); // All characters except the last
            let derivate2 = newValue.slice(-1);    // Last character
            // ensure space between country code and region code
            if (newValue.length === 4 && /^\d*$/.test(derivate2)) {
                newValue = `${derivate1} ${derivate2}`;
            }
            // ensure space between region code and first phone number digits group
            if (newValue.length === 8 && /^\d*$/.test(derivate2)) {
                newValue = `${derivate1} ${derivate2}`;
            }
            // ensure space between the first and second phone number digits groups
            if (newValue.length === 12 && /^\d*$/.test(derivate2)) {
                newValue = `${derivate1} ${derivate2}`;
            }
            setPhoneNumber(newValue);
        }
        if (phoneNumberError) setPhoneNumberError(null)

    };

    return (
        <>
            <Snackbar
                anchorOrigin={{vertical, horizontal}}
                open={open}
                onClose={closeSnackbar}
                message={snackbarMessage}
                key={vertical + horizontal}
            />
            <div>
                <Dialog
                    open={isFormOpen}
                    maxWidth="md"
                    fullWidth={true}
                    PaperProps={{sx: {borderRadius: "12px", backgroundColor: "#3A3E3F"},}}>
                    <DialogContent sx={{p: 4, backgroundColor: "#3A3E3F", color: "#FFFFFF"}}>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                mb: 2,
                                alignItems: "center",
                            }}
                        >
                            <Typography sx={{color: "#FFC540", fontSize: 18}}>
                                Restore Process
                            </Typography>
                        </Box>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    {/*<FormControl {...register("companyName")} fullWidth>*/}
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Company Name"
                                            name="companyName"
                                            type="text"
                                            value={companyName}
                                            onChange={(e) => setCompanyName(e.target.value)}
                                            disabled
                                            InputProps={{
                                                endAdornment: companyName !== "" && (
                                                    <InputAdornment position="end">
                                                        <CheckCircle sx={{color: "#009621"}}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            fullWidth
                                            className={classes.selectLabel}
                                            style={{marginBottom: "12px"}}
                                        />{" "}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    {/*<FormControl {...register("contactName")} fullWidth>*/}
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Contact Name"
                                            name="contactName"
                                            value={contactName}
                                            onChange={(e) => setContactName(e.target.value)}
                                            required
                                            InputProps={{
                                                endAdornment: contactName !== "" && (
                                                    <InputAdornment position="end">
                                                        <CheckCircle sx={{color: "#009621"}}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            className={classes.selectLabel}
                                            style={{marginBottom: "12px"}}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    {/*<FormControl {...register("emailAddress")} fullWidth>*/}
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Email Address"
                                            name="emailAddress"
                                            type="email"
                                            onBlur={(e) => {
                                                if (e.target.value.length > 0) {
                                                    validateEmail(e.target.value);
                                                }
                                            }}
                                            error={emailError}
                                            required
                                            value={email}
                                            InputProps={{
                                                endAdornment: emailError !== null &&
                                                    email !== "" && (
                                                        <InputAdornment position="end">
                                                            {emailError ? (
                                                                <Error color="error"/>
                                                            ) : (
                                                                <CheckCircle sx={{color: "#009621"}}/>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                            }}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                if (emailError) {
                                                    setEmailError(null);
                                                }
                                            }}
                                            className={classes.selectLabel}
                                            style={{marginBottom: "12px"}}
                                            helperText={
                                                emailError &&
                                                "Invalid Email. It should be in the format example@domain.com"
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6}>
                                    {/*<FormControl {...register("phoneNumber")} fullWidth>*/}
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Phone Number"
                                            name="phoneNumber"
                                            value={phoneNumber}
                                            required
                                            className={classes.selectLabel}
                                            style={{marginBottom: "12px"}}
                                            onChange={handlePhoneNumberChange}
                                            onBlur={(e) => {
                                                if (e.target.value.length > 0) {
                                                    validatePhoneNumber(e.target.value);
                                                }
                                            }}
                                            error={phoneNumberError}
                                            InputProps={{
                                                endAdornment: phoneNumberError !== null &&
                                                    phoneNumber !== "" && (
                                                        <InputAdornment position="end">
                                                            {phoneNumberError ? (
                                                                <Error color="error"/>
                                                            ) : (
                                                                <CheckCircle sx={{color: "#009621"}}/>
                                                            )}
                                                        </InputAdornment>
                                                    ),
                                                inputMode: "numeric",
                                                pattern: "[0-9+]*"
                                            }}
                                            helperText={
                                                phoneNumberError &&
                                                "Invalid Phone. +xx xxx xxx xxx format expected"
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                {listFilter == 'itemList' ?
                                    <Grid item xs={12}>
                                        {/*<FormControl {...register("fileName")} fullWidth>*/}
                                        <FormControl fullWidth>
                                            <TextField
                                                label="File Names"
                                                name="fileName"
                                                disabled
                                                value={fileName}
                                                required
                                                InputProps={{
                                                    endAdornment: fileName !== "" && (
                                                        <InputAdornment position="end">
                                                            <CheckCircle sx={{color: "#009621"}}/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                className={classes.selectLabel}
                                                style={{marginBottom: "12px"}}
                                            />
                                        </FormControl>
                                    </Grid> : <></>
                                }
                                <Grid item xs={12}>
                                    {/*<FormControl {...register("tapeVolsers")} fullWidth>*/}
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Tape Volsers"
                                            name="tapeVolsers"
                                            disabled
                                            value={tapeVolsers}
                                            // onChange={(e) => setTapeVolsers(e.target.value)}
                                            required
                                            InputProps={{
                                                endAdornment: tapeVolsers !== "" && (
                                                    <InputAdornment position="end">
                                                        <CheckCircle sx={{color: "#009621"}}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            className={classes.selectLabel}
                                            style={{marginBottom: "12px"}}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    {/*<FormControl {...register("fileName")} fullWidth>*/}
                                    <FormControl fullWidth>
                                        <Tooltip title="Add instructions for the restore here. This can include dates, servers etc">
                                            <TextField
                                                label="Additional Instructions"
                                                name="additionalInstructions"
                                                type="text"
                                                multiline
                                                onChange={(e) => setAdditionalInstructions(e.target.value)}
                                                value={additionalInstructions}
                                                inputProps={{maxLength: 1200}}
                                                rows={4}
                                                className={classes.selectLabel}
                                                style={{marginBottom: "12px"}}
                                            />
                                        </Tooltip>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    {/*<FormControl {...register("methodsAsString")} fullWidth>*/}
                                    <FormControl fullWidth>
                                        <TextField
                                            label="Restore Method"
                                            name="method"
                                            disabled
                                            value={methodsAsString}
                                            required
                                            InputProps={{
                                                endAdornment: methodsAsString !== "" && (
                                                    <InputAdornment position="end">
                                                        <CheckCircle sx={{color: "#009621"}}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            className={classes.selectLabel}
                                            style={{marginBottom: "12px"}}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    {/*<FormControl {...register("urgencyLevel")} fullWidth>*/}
                                    <FormControl fullWidth>
                                        <Tooltip title="Note: Additional charges apply for higher priority restores.">
                                            <TextField
                                                label="Urgency Level"
                                                name="urgencyLevel"
                                                className={classes.selectLabel}
                                                style={{marginBottom: "12px"}}
                                                value={urgencyLevel}
                                                onChange={(e) => setUrgencyLevel(e.target.value)}
                                                select
                                            >
                                                <MenuItem value="high">High</MenuItem>
                                                <MenuItem value="medium">Medium</MenuItem>
                                                <MenuItem value="low">Low</MenuItem>
                                            </TextField>
                                        </Tooltip>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" justifyContent="space-between">
                                    <Grid item>
                                        <StyledButtonOutlined
                                            variant="outlined"
                                            onClick={handleCloseFormDialog}
                                            sx={{width: "200px", height: "60px",}}>
                                            {/*Cancel*/}
                                            Back
                                        </StyledButtonOutlined>
                                    </Grid>
                                    <StyledButtonContained
                                        variant="contained"
                                        type="submit"
                                        disabled={
                                            phoneNumberError ||
                                            phoneNumber === "" ||
                                            emailError ||
                                            email === ""
                                        }
                                        sx={{width: "200px", height: "60px", borderRadius: "30px",}}>
                                        Submit
                                    </StyledButtonContained>
                                </Grid>
                            </Grid>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        </>
    );
};

export default RestoreForm;
