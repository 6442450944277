import React, {useCallback, useEffect, useRef, useState} from "react";
import {Box, Button, Dialog, DialogContent, Drawer, Grid, IconButton, InputAdornment, InputBase, Pagination, PaginationItem, Paper, Stack, Table, TableBody, TableContainer, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup, Toolbar, Tooltip, Typography,} from "@mui/material";
import {PrimaryColor} from "../../component/theme/theme";
import SearchIcon from "@mui/icons-material/Search";
import {Cancel, Close} from "@mui/icons-material";
import {makeStyles} from "@mui/styles";
import Divider from "@mui/material/Divider";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import styled from "@emotion/styled";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import CloseIcon from "@mui/icons-material/Close";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {StyledButtonContained, StyledButtonOutlined,} from "../Admin/AccountCreation/accountCreation";
import axios from "axios";
import {formats} from "./../../global";
import RestoreForm from "./RestoreForm";
import {useFeatures} from "../FeaturesProvider/featuresProvider";
import {TapeDetails} from "../TapeDetails/tapeDetails";
import debounce from 'lodash/debounce';
import moment from 'moment';
import {getLoginUser} from "../../LocalStorageService";
import Menu from "@mui/material/Menu";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {FILES_TABLE_COLUMNS, SEISMIC_CUSTOMER_ARKBRIDGE_IDS, TAPE_TABLE_COLUMNS, TYPICAL_COLUMN_UI_CLASS} from "./restoreTableCustomization";
import {getTapeIcon} from "./tapesIcons";

const bottomDrawerHeight = "5rem";

const CustomFileTypeIcon = ({color, path}) => {
    return (
        <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d={path}/>
        </svg>
    );
};

const videoIconPath =
    "M28.3333 17.5V11.6667C28.3333 11.2246 28.1577 10.8007 27.8452 10.4882C27.5326 10.1756 27.1087 10 26.6667 10H6.66667C6.22464 10 5.80072 10.1756 5.48816 10.4882C5.17559 10.8007 5 11.2246 5 11.6667V28.3333C5 28.7754 5.17559 29.1993 5.48816 29.5118C5.80072 29.8244 6.22464 30 6.66667 30H26.6667C27.1087 30 27.5326 29.8244 27.8452 29.5118C28.1577 29.1993 28.3333 28.7754 28.3333 28.3333V22.5L35 29.1667V10.8333L28.3333 17.5Z";
const spreadsheetIconPath =
    "M31.6667 18.3333V15H18.3333V8.33333H15V15H8.33333V18.3333H15V31.6667H18.3333V18.3333H31.6667ZM31.6667 5C32.5 5 33.3333 5.33333 33.9833 6.01667C34.6667 6.66667 35 7.5 35 8.33333V31.6667C35 32.5 34.6667 33.3333 33.9833 33.9833C33.3333 34.6667 32.5 35 31.6667 35H8.33333C7.5 35 6.66667 34.6667 6.01667 33.9833C5.33333 33.3333 5 32.5 5 31.6667V8.33333C5 7.5 5.33333 6.66667 6.01667 6.01667C6.66667 5.33333 7.5 5 8.33333 5H31.6667Z";
const documentIconPath =
    "M26.666 24.9993H14.9993V21.666H26.666V24.9993ZM31.666 18.3327H14.9993V14.9993H31.666V18.3327ZM31.666 11.666H14.9993V8.33268H31.666V11.666ZM4.99935 8.33268V34.9994H31.666V38.3327H4.99935C4.11529 38.3327 3.26745 37.9815 2.64233 37.3564C2.01721 36.7313 1.66602 35.8834 1.66602 34.9994V8.33268H4.99935ZM34.9994 1.66602C35.8834 1.66602 36.7313 2.01721 37.3564 2.64233C37.9815 3.26745 38.3327 4.11529 38.3327 4.99935V28.3327C38.3327 30.1827 36.8493 31.666 34.9994 31.666H11.666C10.782 31.666 9.93411 31.3148 9.30899 30.6897C8.68387 30.0646 8.33268 29.2167 8.33268 28.3327V4.99935C8.33268 3.14935 9.81602 1.66602 11.666 1.66602H34.9994ZM11.666 4.99935V28.3327H34.9994V4.99935H11.666Z";
const pdfIconPath =
    "M31.6667 5H8.33333C6.5 5 5 6.5 5 8.33333V31.6667C5 33.5 6.5 35 8.33333 35H31.6667C33.5 35 35 33.5 35 31.6667V8.33333C35 6.5 33.5 5 31.6667 5ZM15.8333 19.1667C15.8333 20.5 14.6667 21.6667 13.3333 21.6667H11.6667V25H9.16667V15H13.3333C14.6667 15 15.8333 16.1667 15.8333 17.5V19.1667ZM24.1667 22.5C24.1667 23.8333 23 25 21.6667 25H17.5V15H21.6667C23 15 24.1667 16.1667 24.1667 17.5V22.5ZM30.8333 17.5H28.3333V19.1667H30.8333V21.6667H28.3333V25H25.8333V15H30.8333V17.5ZM20 17.5H21.6667V22.5H20V17.5ZM11.6667 17.5H13.3333V19.1667H11.6667V17.5Z";
const imageIconPath =
    "M14.1667 22.5L18.3333 27.5L24.1667 20L31.6667 30H8.33333M35 31.6667V8.33333C35 6.48333 33.5 5 31.6667 5H8.33333C7.44928 5 6.60143 5.35119 5.97631 5.97631C5.35119 6.60143 5 7.44928 5 8.33333V31.6667C5 32.5507 5.35119 33.3986 5.97631 34.0237C6.60143 34.6488 7.44928 35 8.33333 35H31.6667C32.5507 35 33.3986 34.6488 34.0237 34.0237C34.6488 33.3986 35 32.5507 35 31.6667Z";

const restoreTheme = createTheme({
    palette: {
        primary: {
            main: "#FFC540",
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
                containedSizeMedium: {
                    width: "180px",
                    height: "60px",
                    borderRadius: "30px",
                },
                containedPrimary: {
                    background: "linear-gradient(180deg, #EEAB10 0%, #FFC133 100%)",
                    color: "#3A3E3F",
                },
                containedSecondary: {
                    background: "#3A3E3F",
                    color: "#FFC540",
                    border: "1px solid #FFC540",
                    "&:hover": {
                        backgroundColor: "rgba(255, 197, 64, 0.1)",
                    },
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                    textTransform: "none",
                    background: "transparent",
                    color: "#FFFFFF",
                    "&.Mui-selected": {
                        backgroundColor: "#FFC540",
                        color: "#3E3A3F",
                        "&:hover": {
                            backgroundColor: "#FFC540",
                            color: "#3E3A3F",
                        },
                        "&.file-type": {
                            border: "1px solid #FFC540",
                            background: "rgba(255, 197, 64, 0.1)",
                            color: "#FFC540",
                        },
                    },
                    "&.display-mode": {
                        fontWeight: 700,
                        color: "#FFFFFF",
                        height: 50,
                        width: 140,
                        fontSize: 12,
                    },
                    "&.file-type": {
                        border: "1px solid #FFFFFF",
                        width: "160px",
                        height: "70px",
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    "& .MuiOutlinedInput-input": {
                        color: "#FFFFFF",
                    },
                    "& svg": {
                        color: "#FFFFFF",
                    },
                    "&.number-input": {
                        color: "#FFFFFF",
                        width: "20%",
                        "& fieldset": {
                            borderRadius: "8px 0px 0px 8px",
                            border: "1px solid #FFFFFF",
                        },
                        "&:hover fieldset": {
                            borderColor: "#FFC540",
                        },
                        "&.Mui-focused fieldset": {
                            borderColor: "#FFC540",
                        },
                        "& .MuiInputLabel-root": {
                            color: "rgba(255, 255, 255, 0.5)",
                            "&.Mui-focused": {
                                color: "#FFC540",
                            },
                        },
                    },
                    "&.number-input-uom": {
                        width: "20%",
                        "& fieldset": {
                            border: "1px solid #FFFFFF",
                            borderRadius: "0px 8px 8px 0px",
                        },
                        "&:hover fieldset": {
                            borderColor: "#FFC540",
                        },
                    },
                    "&.date-input": {
                        color: "#FFFFFF",
                        width: "85%",
                        "& fieldset": {
                            width: "100%",
                            borderRadius: "8px",
                            border: "1px solid #FFFFFF",
                        },
                        "&:hover fieldset": {borderColor: "#FFC540",},
                        "&.Mui-focused fieldset": {borderColor: "#FFC540",},
                        "& .MuiInputLabel-root": {
                            color: "#FFFFFF",
                            "&.Mui-focused": {color: "#FFC540",},
                        },
                    },
                },
            },
        },
        MuiTableRow: {
            styleOverrides: {
                root: {
                    borderBottom: "1px solid #5F5F5F",
                    "&:last-child td, &:last-child th": {border: 0,},
                },
            },
        },
        MuiTooltip: {styleOverrides: {tooltip: {"&.wideTooltip": {maxWidth: "60em",},},},},
    },
});
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({theme}) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
            transform: "translateX(16px)",
            color: "#fff",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.palette.mode === "dark" ? "" : "#FFC540",
                opacity: 1,
                border: 0,
            },
            "&.Mui-disabled + .MuiSwitch-track": {opacity: 0.5,},
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {border: "6px solid #fff",},
        "&.Mui-disabled .MuiSwitch-thumb": {color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],},
        "&.Mui-disabled + .MuiSwitch-track": {opacity: theme.palette.mode === "light" ? 0.7 : 0.3,},
    },
    "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
        color: "#FFFFFF",
    },
    "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "" : "#FFC540",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {duration: 500,}),
    },
}));

const useStyles = makeStyles({
    rootMenuItem: {
        "&$selected": {
            backgroundColor: "red",
            "&:hover": {
                backgroundColor: "green",
                borderRadius: 12,
                border: "1px solid var(--White, #FFF)",
            },
        },
        "&:hover": {
            backgroundColor: "transparent",
            borderRadius: 12,
            border: "1px solid var(--White, #FFF)",
        },
    },
    highlight: {
        background: "#FFC540",
        color: "#3A3E3F",
    },
    checkedColr: {
        "&.Mui-checked": {color: "red",},
    },
    selectLabel: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderRadius: "8px",
                borderColor: "#C7CCD0",
            },
            "&:hover fieldset": {borderColor: "#C7CCD0",},
            "&.Mui-focused fieldset": {borderColor: "#C7CCD0",},
        },
        "& .MuiOutlinedInput-input": {color: "#FFFFFF",},
        "& .MuiInputLabel-root": {
            color: "rgba(255, 255, 255, 0.5)",
            "&.Mui-focused": {color: "#FFC540",},
        },
        "& svg": {color: "#FFFFFF",},
    },
});

const StyledTableCell = styled(TableCell)(() => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#3A3E3F",
        color: "#F9F9F9",
    },
    [`&.${tableCellClasses.body}`]: {fontSize: 14,},
}));

const StyledTableRow = styled(TableRow)(() => ({
    "&.MuiTableRow-root": {
        "& > *": {
            color: "#FFFFFF",
            backgroundColor: "#3A3E3F",
            border: "20px solid #FFFFFF1A",
            borderRadius: "8px",
        },
    },
    // hide last border
    "&:last-child td, &:last-child th": {border: 0,},
}));

const dateOptions = {year: "numeric", month: "short", day: "numeric"};
const docType = {
    TEXT_DOCUMENT: "Document",
    SPREADSHEET: "Spreadsheet",
    VIDEO: "Video",
    IMAGE: "Image",
    PDF: "PDF",
};

const docTypeOverview = [
    {
        docType: docType.TEXT_DOCUMENT,
        iconPath: documentIconPath,
        count: 355759,
        size: "455.11 TB",
        selected: false,
    },
    {
        docType: docType.PDF,
        iconPath: pdfIconPath,
        count: 145442,
        size: "3.12 TB",
        selected: false,
    },
    {
        docType: docType.VIDEO,
        iconPath: videoIconPath,
        count: 511285,
        size: "1203 PB",
        selected: false,
    },
    {
        docType: docType.IMAGE,
        iconPath: imageIconPath,
        count: 224145,
        size: "480.64 TB",
        selected: false,
    },
    {
        docType: docType.SPREADSHEET,
        iconPath: spreadsheetIconPath,
        count: 12409,
        size: "26.11 TB",
        selected: false,
    },
];

/**
 * Function to check if an object is an array of strings.
 * @param obj
 * @returns {boolean}
 */
export function isArrayOfStrings(obj) {
    return Array.isArray(obj) && obj.every(item => typeof item === "string");
}

/**
 * Function to check if an object is a string.
 * @param dataToFormat
 * @returns {boolean}
 */
export function isString(dataToFormat) {
    return typeof dataToFormat === "string";
}

/**
 * Function to format bytes to the largest unit possible.
 * @param bytes - Number of bytes to be formatted. If 0 or not a number, returns 0.
 * @param decimals - Number of decimal places to be displayed. If lower than 0, defaults to 0.
 * @returns {string} - Formatted string with the largest unit possible.
 */
const formatBytes = (bytes, decimals = 0) => {
    if (!+bytes) return '0'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

/**
 * Function to format a string or a list of strings into a single string.
 * @param {string | string[]} dataToFormat - Data to be formatted.
 * @returns {string} - Formatted string.
 */
const formatString = (dataToFormat) => {
    if (isString(dataToFormat)) {
        return dataToFormat;
    } else if (isArrayOfStrings(dataToFormat)) {
        return dataToFormat.join(", ");
    } else {
        return undefined;
    }
}

/**
 * Function to format a date string into a 'DD/MMM/YYYY' string.
 * @param dataToFormat - Date string to be formatted.
 * @returns {string} - Formatted date string.
 */
const formatDateTime = (dataToFormat) => {
    // possible formats:
    // 2025-01-15 17:41:58
    if (typeof dataToFormat !== "string") return undefined;

    const dateFormats = [
        'DD/MMM/YYYY',
        'D/MMM/YYYY',
        'DD/MM/YYYY',
        'D/MM/YYYY',
        'DD-MM-YYYY',
        'D-MM-YYYY',
        'YYYY-MM-DD',
        'YYYY-M-D'
    ];
    const dateTimeFormats = dateFormats.map(format => `${format} HH:mm:ss`).concat(dateFormats.map(format => `${format} H:m:s`));
    const dateTimeOffsetFormats = dateFormats.map(format => `${format} HH:mm:ssZ`).concat(dateFormats.map(format => `${format} H:m:sZ`));
    const allFormats = [...dateFormats, ...dateTimeFormats, ...dateTimeOffsetFormats];

    const parsedDate = moment(dataToFormat, allFormats, true);

    if (!parsedDate.isValid()) return undefined;
    return parsedDate.format('DD/MMM/YYYY');
}

/**
 * Function to format an integer as a string.
 * @param {number} dataToFormat
 * @returns {string} - Formatted integer.
 */
const formatInt = (dataToFormat) => {
    return `${dataToFormat}`;
}

/**
 * Function to format an integer as a string with grouping of thousands.
 * @param dataToFormat
 * @returns {string}
 */
const formatGroupedInt = (dataToFormat) => {
    return new Intl.NumberFormat('en-US', {useGrouping: true})
        .format(dataToFormat)
        .replace(/,/g, ' ');
}

/**
 * Method to parse the data from the API to the internal format.
 * @param data
 * @param {string} ui_class_to_format_to - The ui_class that sets the way UI will format the data. str, data_size, date
 * @returns {string} - Parsed data as a string.
 */
const formatData = (data, ui_class_to_format_to) => {
    if (!data) return "";
    if (!ui_class_to_format_to) return "";

    const functionMap = [
        {
            ui_class: "str",
            fn: formatString
        },
        {
            ui_class: "filename",
            fn: formatString
        },
        {
            ui_class: "filepath",
            fn: formatString
        },
        {
            ui_class: "list",
            fn: formatString
        },
        {
            ui_class: "data_size",
            fn: formatBytes
        },
        {
            ui_class: "data_size_bytes",
            fn: (data_size) => {
                return `${formatGroupedInt(data_size)} bytes`;
            }
        },
        {
            ui_class: "int",
            fn: formatInt
        },
        {
            ui_class: "datetime",
            fn: formatDateTime
        },
        {
            ui_class: "date",
            fn: formatDateTime
        },
        {
            ui_class: "duration_ms",
            fn: (duration) => {
                return `${formatGroupedInt(duration)} μs`;
            }
        },
    ];
    const match = functionMap.find(({ui_class}) => ui_class === ui_class_to_format_to);
    if (match) {
        return match.fn(data);
    } else {
        return "";
    }
}

/**
 * For a given element, it returns the formatted property based on the column type. It caches the formatted property in the element object.
 * @param element
 * @param {string} mnemonic
 * @param {string} columnType
 * @returns {string}
 */
export const _getFormattedProperty = (element, mnemonic, columnType) => {
    const formattedPropertyName = `__${mnemonic}_formatted_as_${columnType}`;
    if (!element[formattedPropertyName]) {
        const rawData = element?.attributes && element.attributes?.[mnemonic]?.value ? element.attributes[mnemonic].value : element[mnemonic];
        element[formattedPropertyName] = formatData(rawData, columnType);
    }
    return element[formattedPropertyName];
};

export const Restore = () => {
    const userDetails = getLoginUser(); // userDetails.user.arkbridge_customer_id
    const [listFilter, setListFilter] = React.useState("tapeList");
    const [tableColumns, setTableColumns] = React.useState(TAPE_TABLE_COLUMNS["Standard tapes"]);
    const [selectedData, setSelectedData] = React.useState();
    const [openTapeDetails, setOpenTapeDetails] = React.useState(false);
    const [fileTypeFilter, setFileTypeFilter] = useState(() => []);
    const isFileTypeFilterSelected = (fileType) => {
        return fileTypeFilter.find((type) => type === fileType);
    };
    const [allRestoreList, setAllRestoreList] = useState([]);
    const features = useFeatures()
    const [restoreData, setRestoreData] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [totalFetched, setTotalFetched] = useState(false);

    const [filesTypeAnchorEl, setFilesTypeAnchorEl] = React.useState(null);
    const [filesType, setFilesType] = React.useState('Standard files view');

    /**
     * Attaches a 'change listener' to the 'features' state. When 'features' change, the user type will be checked (admin or not) and internal states updated accordingly.
     */
    useEffect(() => {
        if (features && features?.features?.find((feature) => feature === 'admin_dashboard')) { //  Slightly more performant version: if (features && features?.features?.includes('admin_dashboard'))
            setIsAdmin(true);
            if (!totalFetched) {
                // getRestoreData(true)
                getItemCount(true);
                setTotalFetched(true);
            }
        } else if (features && features?.features?.find((feature) => feature !== 'admin_dashboard')) {//  Slightly more performant version: else if (features && Array.isArray(features?.features) && !features.features.includes('admin_dashboard'))
            setIsAdmin(false);
            if (!totalFetched) {
                // getRestoreData(false)
                getItemCount(false);
                setTotalFetched(true);
            }
        }
    }, [features])

    const clearRestoreTypeSelection = () => {
        setPhysicalshow(false);
        setCloudshow(false);
    }

    /**
     * Debounce function to limit the number of API calls with a delay of 500ms. Depending on _listFilter, getItemCount or getFileCount will be called. At their turn, these functions affect the states addedRows and restoreData.
     * @type {useCallback}
     * @param {Event} e - Event object
     * @param {string} _listFilter - Filter to be applied. Either 'tapeList' or 'itemList'.
     */
    const functionDebounce = useCallback(
        debounce((e, _listFilter) => {
            if (_listFilter == 'tapeList') {
                getItemCount(isAdmin, e?.target?.value);
            } else if (_listFilter == 'itemList') {
                getFileCount(isAdmin, e?.target?.value);
            }
        }, 1000),
        []
    )


    // man_item_count
    /**
     * Fetches from the SaaS API the count of files.
     * Side effects: updates the internal states totalPages (multiple of 10), tapeCount and calls getFileData for page 1.
     * If nothing is returned, defaults to 0.
     * @param {boolean} _isAdmin - Admin or non-admin user
     * @param {string} [search=""] - Search query
     * @returns {void}
     */
    const getFileCount = (_isAdmin, search = "") => {

        let payload = {
            type: "man_search_file_count",
            request: {
                cid: userDetails.user.arkbridge_customer_id,
                type: "man_search_file_count",
                // page_number: 1,
                // page_size: 10,
                search: search ? search : undefined
            },
        };
        axios
            .post(formats, payload)
            .then((response) => {
                if (response?.status == 200) {
                    let _count = response?.data?.result?.data?.count ? response?.data?.result?.data?.count : 0;
                    setTotalPages(Math.ceil(_count / 10));
                    setTapeCount(_count);
                    getFileData(_isAdmin, 1, payload.request.search);
                }
            })
            .catch((error) => {
                console.log(error);
                console.log("getFileCount error", userDetails.user);
            });
    }

    /**
     * Fetches from the SaaS API the files' data.
     * Side effects: updates the internal states allRestoreList, restoreData and calls mapAddedRows for the first max 10 items returned.
     * @param {boolean} _isAdmin - Admin or non-admin user
     * @param {number} pageNumber - Page number
     * @param {string} [search=""] - Search query
     */
    const getFileData = (_isAdmin, pageNumber, search = "") => {
        let payload = {
            type: "man_search_file",
            request: {
                cid: userDetails.user.arkbridge_customer_id,
                type: "man_search_file",
                page_number: pageNumber - 1,
                page_size: 10,
                search: search ? search : undefined,
                file_properties_as_attributes: true
            },
        };
        axios
            .post(formats, payload)
            .then((response) => {
                if (response?.status == 200) {
                    let dynamicArray;
                    let dynamicObject;
                    if (!_isAdmin) {
                        dynamicObject = Object.values(response.data.result);
                        dynamicObject.forEach(i => dynamicArray = i.data);
                    } else {
                        dynamicArray = [];
                        dynamicObject = Object.values(response.data.result);
                        dynamicObject[1].forEach(i => dynamicArray.push(i));
                    }
                    dynamicArray.map((file_item) => {
                        file_item.item_id = file_item?.["file_id"];
                    })
                    setAllRestoreList(dynamicArray);
                    setRestoreData(dynamicArray.slice(0, 10));
                    mapAddedRows(dynamicArray.slice(0, 10));
                }
            })
            .catch((error) => {
                console.log(error);
                console.log("getFileCount error", userDetails.user);
            });
    }

    /**
     * Fetches from the SaaS API the count of tapes.
     * Side effects: updates the internal states totalPages (multiple of 10), tapeCount and calls getRestoreData for page 1.
     * If nothing is returned, defaults to 0.
     * @param {boolean} _isAdmin - Admin or non-admin user
     * @param {string} [search=""] - Search query
     * @returns {void}
     */
        // man_item_count
    const getItemCount = (_isAdmin, search = "") => {
            let payload = {
                type: "man_item_count",
                request: {
                    cid: userDetails.user.arkbridge_customer_id,
                    type: "man_item_count",
                    search: search ? search : undefined
                },
            };
            axios
                .post(formats, payload)
                .then((response) => {
                    if (response?.status == 200) {
                        let _count = response?.data?.result?.data?.count ? response?.data?.result?.data?.count : 0;
                        setTotalPages(Math.ceil(_count / 10));
                        setTapeCount(_count);
                        getRestoreData(_isAdmin, 1, payload.request.search);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    console.log("getFileCount error", userDetails.user);
                });
        }

    /**
     * Fetches from the SaaS API the tapes' data. It fetches the data for max 10 tapes of a specific page.
     * Side effects: updates the internal states allRestoreList, restoreData and calls mapAddedRows for the first max 10 items returned.
     * @param {boolean} _isAdmin - Admin or non-admin user
     * @param {number} pageNumber - Page number
     * @param {string} [search=""] - Search query
     */
    const getRestoreData = (_isAdmin, pageNumber, search = "") => {
        let payload = {
            type: "man_item_list",
            request: {
                cid: userDetails.user.arkbridge_customer_id,
                type: "man_item_list",
                page_number: pageNumber - 1,
                page_size: 10,
                search: search ? search : undefined
            },
        };
        axios
            .post(formats, payload)
            .then((response) => {
                if (response?.status == 200) {
                    let dynamicArray;
                    let dynamicObject;
                    if (!_isAdmin) {
                        dynamicObject = Object.values(response.data.result)
                        dynamicObject.forEach(i =>
                            dynamicArray = i.data)
                    } else {
                        dynamicArray = [];
                        dynamicObject = Object.values(response.data.result)
                        dynamicObject[1].forEach(i => dynamicArray.push(i))
                    }
                    setAllRestoreList(dynamicArray);
                    setRestoreData(dynamicArray.slice(0, 10));
                    mapAddedRows(dynamicArray.slice(0, 10));
                }
            })
            .catch((error) => {
                console.log(error);
                console.log("getFileCount error", userDetails.user);
            });
    }

    /**
     * Instantiates the addedRows React state as an empty array.
     */
    const [addedRows, setAddedRows] = useState([]);

    /**
     * Function to add a row to the addedRows state.
     * It sets the `selected` property to true for the added row.
     * It updates the restoreData state to reflect the added row.
     * @type {function}
     * @param row
     */
    const handleAddRow = (row) => {
        const newRestoreData = [...restoreData];
        const matchedRow = newRestoreData.find(
            (newDataRow) => newDataRow?.item_id === row?.item_id
        );
        if (matchedRow) {
            matchedRow.selected = true;
            setRestoreData(newRestoreData);
            setAddedRows([...addedRows, row]);
        }
    };

    /**
     * Function cycles through all addedRows and sets as `selected` the rows common with the provided Array of Objects.
     * It then updates the restoreData state to reflect the changes.
     * @param {Object[]} _restoreData - Array of objects to be mapped
     */
    const mapAddedRows = (_restoreData) => {
        const newRestoreData = [..._restoreData];
        addedRows.forEach((addedRow) => {
            let selectedRecord = newRestoreData.find(
                (newDataRow) => newDataRow?.item_id === addedRow?.item_id
            ); // selectedRecord (tape or file object) common to _restoreData and addedRows state
            if (selectedRecord) {
                selectedRecord.selected = true;
            }
        })
        setRestoreData(newRestoreData);
    }

    /**
     * Function to remove a row from the addedRows state.
     * It sets the `selected` property to false for the removed row and updates the restoreData state to reflect the changes.
     * @type {function}
     * @param row - Row to be removed
     */
    const handleRemoveRow = (row) => {
        const newRestoreData = [...restoreData];
        let selectedData = newRestoreData.find((oldDataRow) => oldDataRow?.item_id === row?.item_id);
        if (selectedData) {
            selectedData.selected = false;
            setRestoreData(newRestoreData);
        }
        const updateAddedRows = addedRows.filter((oldDataRow) => oldDataRow?.item_id !== row?.item_id);
        setAddedRows(updateAddedRows);
    };

    /**
     * Function to clear all added rows. It sets the `selected` property to false for all rows and empties the addedRows state.
     * Update the restoreData state to reflect the changes.
     * @type {function}
     */
    const clearAddedRows = () => {
        const newRestoreData = [...restoreData];
        newRestoreData.forEach((row) => (row.selected = false));
        setRestoreData(newRestoreData);
        setAddedRows([]);
    };

    const classes = useStyles();
    // const totalPages = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [tapeCount, setTapeCount] = useState(0);

    const [sortBy, setSortBy] = useState("columnName");
    const [sortOrder, setSortOrder] = useState("asc");
    const [checkoutDialogRestoreSummaryOpenFlag, setCheckoutDialogRestoreSummaryOpenFlag] = React.useState(false);
    const [showadd, setShowadd] = React.useState([
        {
            id: "",
            show: true,
        },
    ]);
    const [addcount, setaddCount] = React.useState(0);
    const [selectedTape, setSelectedTape] = React.useState([
        {
            id: "",
            name: "",
            tape: "",
        },
    ]);

    // TODO: handleContinue function not used. To be removed if not needed.
    const handleContinue = () => {
        if (physicalshow) {
            setCheckoutDialogRestorePreferencesOpenFlag(false);
            setAdressDialog(true);
            // setCheckoutDialog(true);
        } else {
            setCheckoutDialogRestorePreferencesOpenFlag(false);
            setCheckoutDialogRestoreSummaryOpenFlag(true);
        }
    };

    const handleClickCancel = () => {
        //
        if (physicalshow) {
            setCheckoutDialogRestoreSummaryOpenFlag(false);
            // setAdressDialog(true);
        } else {
            setCheckoutDialogRestoreSummaryOpenFlag(false);
            setCheckoutDialogRestorePreferencesOpenFlag(true);
        }
    };
    const [id, setId] = React.useState();

    // TODO: onClickAdd function not used. To be removed if not needed.
    const onClickAdd = (id, name, tape) => {
        setId(id);
        setSelectedTape([
            ...selectedTape,
            {
                id: id,
                name: name,
                tape: tape,
            },
        ]);
        setaddCount(addcount + 1);
        setShowadd(false);
    };

    // TODO: onclickRemove function not used. To be removed if not needed.
    const onclickRemove = (id, name, tape) => {
        const data = [...selectedTape];
        data.splice(id, 1);
        setSelectedTape(data);
        setId(id);
        setShowadd(true);
    };
    const [cloudshow, setCloudshow] = React.useState(false);
    const [physicalshow, setPhysicalshow] = React.useState(false);
    const [addressDialog, setAdressDialog] = React.useState(false);
    const [country, setCountry] = React.useState("");
    const [state, setState] = React.useState("");
    const onChangeCloud = (e) => {
        e.target.checked ? setCloudshow(true) : setCloudshow(false);
    };
    const onChangephysical = (e) => {
        e.target.checked ? setPhysicalshow(true) : setPhysicalshow(false);
    };
    // Filter Popup
    const [filterPopupOpen, setFilterPopupOpen] = React.useState(false);
    // To open the filter popup
    const handleOpenFilterPopup = () => {
        setFilterPopupOpen(true);
    };
    // To close the filter popup
    const handleCloseFilterPopup = () => {
        setFilterPopupOpen(false);
    };

    // Checkout Dialog
    const [checkoutDialogRestorePreferencesOpenFlag, setCheckoutDialogRestorePreferencesOpenFlag] = useState(false);
    let checkoutRestorePreferences = useRef(
        {
            "cloudRestoreSignedURL": {"type": "Cloud Restore", "text": "I want to have a signed URL for my files", "value": false},
            "cloudRestoreSpecificTimeAvailability": {"type": "Cloud Restore", "text": "I want the files to be accessible for a specific time.", "value": false},
            "cloudRestoreTapeNamedFolder": {"type": "Cloud Restore", "text": "I want the files to be put into the folder of tape names they belong to.", "value": false},
            "physicalRestoreDifferentAddress": {"type": "Physical Restore", "text": "I want the files to be sent to a different addresses than the source", "value": false},
            "physicalRestoreDiskTypeSelection": {"type": "Physical Restore", "text": "I want to choose the disk type on which the files are sent", "value": false},
            "physicalRestoreDiskEncryption": {"type": "Physical Restore", "text": "I want the disk to have encryption enabled by default", "value": false},
        });

    useEffect(() => {
        if (checkoutDialogRestorePreferencesOpenFlag) {
            checkoutRestorePreferences.current = {
                "cloudRestoreSignedURL": {"type": "Cloud Restore", "text": "I want to have a signed URL for my files", "value": false},
                "cloudRestoreSpecificTimeAvailability": {"type": "Cloud Restore", "text": "I want the files to be accessible for a specific time.", "value": false},
                "cloudRestoreTapeNamedFolder": {"type": "Cloud Restore", "text": "I want the files to be put into the folder of tape names they belong to.", "value": false},
                "physicalRestoreDifferentAddress": {"type": "Physical Restore", "text": "I want the files to be sent to a different addresses than the source", "value": false},
                "physicalRestoreDiskTypeSelection": {"type": "Physical Restore", "text": "I want to choose the disk type on which the files are sent", "value": false},
                "physicalRestoreDiskEncryption": {"type": "Physical Restore", "text": "I want the disk to have encryption enabled by default", "value": false},
            };
        }
    }, [checkoutDialogRestorePreferencesOpenFlag]);

    // To open checkout dialog
    const handleOpenCheckoutDialog = () => {
        setCheckoutDialogRestorePreferencesOpenFlag(true);
    };
    // To close checkout dialog
    const handleCloseCheckoutDialog = () => {
        setCheckoutDialogRestorePreferencesOpenFlag(false);
    };

    // Form Dialog
    const [isFormOpen, setIsFormOpen] = useState(false);
    // To open form dialog
    const handleOpenFormDialog = () => {
        setIsFormOpen(true);
    };
    // To close form dialog
    const handleCloseFormDialog = () => {
        setIsFormOpen(false);
    };

    // Restores List display mode
    const [displayMode, setDisplayMode] = useState("All");
    const handleDisplayModeChange = (event, mode) => {
        if (mode !== null) {
            setDisplayMode(mode);
        }
    };

    /**
     * Function that updates the state of the current page and depending on listing tapes or files, calls the corresponding function to fetch the data from SaaS API.
     * @param event
     * @param {number} newPage - New page number
     */
    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
        if (listFilter == 'tapeList') {
            getRestoreData(isAdmin, newPage, searchQuery);
        } else {
            getFileData(isAdmin, newPage, searchQuery);
        }
    };

    const startIndex = (currentPage - 1) * 10 + 1;
    const endIndex = Math.min(currentPage * 10, tapeCount);

    // TODO: handleSort function not used. To be removed if not needed.
    const handleSort = (column) => {
        if (column === sortBy) {
            setSortOrder(sortOrder === "asc" ? "desc" : "asc");
        } else {
            setSortBy(column);
            setSortOrder("asc");
        }
    };

    const handleCloseAddress = () => {
        setCheckoutDialogRestorePreferencesOpenFlag(true);
        setAdressDialog(false);
    };

    const handleAddress = () => {
        setCheckoutDialogRestoreSummaryOpenFlag(true);
        setAdressDialog(false);
    };

    const showReset = () => {
        setPhysicalshow(false)
        setCloudshow(false)
    }

    const [searchQuery, setSearchQuery] = useState("");
    /**
     * Function to handle the search query. It debounces the search query and calls the corresponding function to fetch the data (tapes or files) from SaaS API.
     * It also updates the searchQuery state.
     * @param event
     */
    const handleSearchQuery = (event) => {
        functionDebounce(event, listFilter);
        setSearchQuery(event.target.value);
    };

    /**
     * Function to highlight the search query in the text. It generates components and the ones that match the search query, have the highlight class.
     * @param id - id not used
     * @param {string} text - Text to be compared with the search query
     * @returns - Array of components
     */
    const highlightText = (id, text) => {
        if (!searchQuery || searchQuery === "") {
            return text;
        }
        const regex = new RegExp(`(${searchQuery})`, "gi");
        const parts = text?.split(regex);
        return parts?.map((part, index) =>
            part?.toLowerCase() === searchQuery.toLowerCase() ? (
                <Typography sx={{fontSize: 14}} className={classes.highlight} component="span">
                    {part}
                </Typography>
            ) : (
                <Typography sx={{fontSize: 14}} component="span">{part}</Typography>
            )
        );
    };

    const getTapeImage = (media_type) => {
        // let _data = TAPES_ARRAY.filter(e => e.fileName.includes(media_type));
        // return _data?.length ? _data[0].filePath : TAPES_ARRAY[TAPES_ARRAY.length]?.filePath;

        return getTapeIcon(media_type);
    }

    const [filterCount, setFilterCount] = useState(0);
    const selectDetail = (row) => {
        setSelectedData(row?.item_id);
        setOpenTapeDetails(true);
    }

    /**
     * helper function that generates the internal content used later to populate the content of a table cell
     * @param column
     * @param row
     * @returns {React.JSX.Element|string|*[]|*}
     */
    const dynamicContentForCellHelper = (column, row) => {
        let columnType = undefined;
        if (listFilter == 'tapeList') {
            // tapes are displayed, get type from column (each entry of TAPE_TABLE_COLUMNS)
            columnType = column?.ui_class;
        } else {
            // files are displayed
            if (row?.attributes && row.attributes?.[column.mnemonic]) {
                if (row?.attributes?.[column.mnemonic]?.ui_class) {
                    // get ui_class from attributes -> mnemonic
                    columnType = row.attributes[column.mnemonic].ui_class;
                } else {
                    // default to data_type from attributes -> mnemonic
                    columnType = row.attributes[column.mnemonic].data_type;
                }
            } else {
                // try getting the ui_class from TYPICAL_COLUMN_UI_CLASS if it exists or default to 'str'
                columnType = TYPICAL_COLUMN_UI_CLASS[column.mnemonic] ? TYPICAL_COLUMN_UI_CLASS[column.mnemonic] : 'str';
            }

        }

        const formattedProperty = _getFormattedProperty(row, column.mnemonic, columnType);

        return column.special_render === "tape_photo" ?
            (
                row.media_type ?
                    (<img onClick={() => {
                        selectDetail(row)
                    }} style={{height: '75px', cursor: 'pointer'}} src={getTapeImage(formattedProperty)}/>)
                    : (<div></div>)
            )
            : ((listFilter == "itemList" && row?.attributes?.[column.mnemonic]?.searchable) || (listFilter == "tapeList" && column?.searchable) ?
                    highlightText(row.item_id, formattedProperty)
                    : formattedProperty
            );
    };

    /**
     * Helper function that generates the internal content used later to populate the tooltip of a table cell.
     * @param row
     * @param column
     * @returns {*[]}
     */
    function dynamicTooltipHelper(row, column) {
        const maxCharactersPerLine = 80; // Limit each line to 80 characters
        const maxTotalRows = 40; // Limit the total rows in the tooltip

        /**
         * Function to split long values into lines of 80 characters
         * @param value
         * @returns {*[]}
         */
        const wrapValue = (value) => {
            const lines = [];
            for (let i = 0; i < value.length; i += maxCharactersPerLine) {
                lines.push(value.slice(i, i + maxCharactersPerLine));
            }
            return lines;
        };

        // Build tooltip content and enforce the 40-row limit
        const rows = [];
        for (const tooltipEntry of column?.tooltip) {
            const tooltipKey = tooltipEntry?.name;
            const tooltipEntryValue = _getFormattedProperty(row, tooltipEntry?.mnemonic, tooltipEntry?.ui_class);
            const tooltipEntryWrappedValue = wrapValue(tooltipEntryValue);

            tooltipEntryWrappedValue.forEach((line, index) => rows.push(
                <div>
                    {index === 0 && tooltipKey ? (<strong>{tooltipKey}: </strong>) : ""}{line}
                </div>));
            if (rows.length >= maxTotalRows) break;
        }

        return rows.slice(0, maxTotalRows);
    }

    /**
     * function to render the table cell. It generates the cell content based on the column and row data.
     * @param row
     * @param {number} rowIndex
     * @param column
     * @param {number} colIndex
     * @returns {JSX.Element}
     */
    const dynamicTableCell = (row, rowIndex, column, colIndex) => {
        // return empty cell if no column
        if (!column) return (
            <TableCell colSpan={2} align="centre">
                <div style={{textAlign: "center", width: "50%", margin: "0", left: "50%", alignItems: "center", color: "#fff"}}></div>
            </TableCell>);

        return (
            <TableCell>
                <Typography sx={{color: "#FFFFFF", fontSize: 14, fontFamily: "Poppins", fontWeight: row.selected ? 700 : 400}}
                            className={(listFilter == "itemList" && row?.attributes?.[column.mnemonic]?.searchable) || (listFilter == "tapeList" && column?.searchable) ? ("restore-name") : ""}
                            key={rowIndex}
                            id={`restore-${column.mnemonic}-${rowIndex}`}>
                    {column.tooltip ?
                        (
                            <Tooltip title={dynamicTooltipHelper(row, column)} slotProps={{tooltip: {className: "wideTooltip"},}}>
                                {dynamicContentForCellHelper(column, row)}
                            </Tooltip>
                        )
                        : dynamicContentForCellHelper(column, row)}
                </Typography>
            </TableCell>);
    }

    /**
     * Function to handle the change of the list filter (the name used by Minphy to reflect that Restore displays a list of tapes or a list of files to be restored).
     * It toggles between tapeList and itemList.
     * It updates the listFilter state, resets the search query, clears the added rows and sets the current page to 1.
     * Ultimately, it calls the corresponding function to fetch the data (tapes or files) from SaaS API.
     * @param e - Event object
     */
    const listFilterChange = (e) => {
        setListFilter(e.target.value);
        setSearchQuery("");
        clearAddedRows();
        setCurrentPage(1);
        if (e.target.value == 'tapeList') {
            getItemCount(isAdmin);
            setTableColumns(TAPE_TABLE_COLUMNS["Standard tapes"]);
        } else if (e.target.value == 'itemList') {
            getFileCount(isAdmin);
            setTableColumns(FILES_TABLE_COLUMNS[filesType]);
        }
    }

    const clearSearch = () => {
        setSearchQuery("");
        if (listFilter == 'tapeList') {
            getItemCount(isAdmin, "");
        } else if (listFilter == 'itemList') {
            getFileCount(isAdmin, "");
        }
    }

    /**
     * Function to render the Add or Remove button cell. It generates the button based on the selected status of row data.
     * @param row
     * @returns {Element}
     */
    const dynamicAddRemoveButtonCell = (row) => {
        return <TableCell style={{textAlign: "center"}}>
            {row.selected ?
                (<StyledButtonOutlined
                        variant="outlined"
                        onClick={() => {
                            handleRemoveRow(row);
                        }}
                        sx={{
                            border: "1px solid #DD2A19",
                            background: "#FFFFFF",
                            color: "#DD2A19",
                            borderRadius: 20,
                            width: 80,
                            height: 40,
                            textTransform: "none",
                        }}>
                        Remove
                    </StyledButtonOutlined>
                ) : (
                    <StyledButtonOutlined
                        variant="outlined"
                        onClick={() => {
                            handleAddRow(row);
                        }}
                        sx={{
                            border: "1px solid #FFC540",
                            background: "transparent",
                            color: "#FFC540",
                            borderRadius: 20,
                            width: 80,
                            height: 40,
                            textTransform: "none",
                        }}>
                        Add
                    </StyledButtonOutlined>
                )}
        </TableCell>;
    };

    /**
     * Function to render a table cell for no data situation.
     * @returns {Element}
     */
    const noDataCell = () => {
            return <TableCell colSpan={2} align="centre">
                <div style={{textAlign: "center", width: "50%", margin: "0", left: "50%", alignItems: "center", color: "#fff"}}>Data not Found</div>
            </TableCell>;
        }
    ;

    /**
     * ====================================================================================================================
     *                                              FilesType selector section.
     * ====================================================================================================================
     * States and functions used for the files type selector to allow the switch between different views of the files table.
     */
    const handleFilesTypeSelectorClick = (event) => {
        setFilesTypeAnchorEl(event.currentTarget);
    };

    /**
     * Function called when the menu needs to be closed. This updates teh filesTypeAnchorEl which is a state that influences the open property of the menu.
     */
    const handleFilesTypeMenuClose = () => {
        setFilesTypeAnchorEl(null);
    };

    /**
     * Function called when a files type (required for files table rendering) is changed. The state is updated with the new value and the menu closed
     * @param {string} newFilesType
     */
    const handleFileTypeSelection = (newFilesType) => {
        handleFilesTypeMenuClose();
        setFilesType(newFilesType);
        setTableColumns(FILES_TABLE_COLUMNS[newFilesType]);
    };

    /**
     * Function to render the button that allows switching between the different displays of the files table (different columns selections).
     * @returns {JSX.Element}
     */
    const filesTypeSelector = () => {
        return (
            <ToggleButtonGroup
                color="primary"
                sx={{
                    "& .MuiToggleButtonGroup-grouped": {
                        margin: 0.5,
                        border: 0,
                        "&.Mui-selected": {color: "#4E5152",},
                    },
                }}
                exclusive
                aria-label="Selector for files type"
                value={null}>
                <ToggleButton
                    className="display-mode"
                    variant={"outlined"}
                    value={filesType}
                    onClick={handleFilesTypeSelectorClick}>
                    {filesType}
                    <ArrowDropDownIcon sx={{marginLeft: 1}}/>
                </ToggleButton>
                <Menu
                    anchorEl={filesTypeAnchorEl}
                    open={Boolean(filesTypeAnchorEl)}
                    onClose={handleFilesTypeMenuClose}>
                    <MenuItem onClick={(event) => handleFileTypeSelection("Seismic SegD view")}>
                        <Typography sx={{fontSize: 12}} component="span">Seismic SegD view</Typography>
                    </MenuItem>
                    <MenuItem onClick={(event) => handleFileTypeSelection("Seismic SegY view")}>
                        <Typography sx={{fontSize: 12}} component="span">Seismic SegY view</Typography>
                    </MenuItem>
                    <Divider sx={{border: "0.5px groove #C7CCD0"}}/>
                    <MenuItem onClick={(event) => handleFileTypeSelection("Standard files view")}>
                        <Typography sx={{fontSize: 12}} component="span">Standard files view</Typography>
                    </MenuItem>
                </Menu>
            </ToggleButtonGroup>);
    };

    /**
     * ====================================================================================================================
     *                                 Checkout preferences section (dialogs and components).
     * ====================================================================================================================
     */

    /**
     * Returns a checkbox component and a label for the checkbox.
     * @param {string} refName - The name of the reference that the checkbox impacts.
     */
    const getCheckBoxPreferences = (refName) => {
        const handleCheckboxChange = (event) => {
            if (!checkoutRestorePreferences.current?.[refName]?.value === undefined) {
                console.log(`Preference (${refName}) not expected to be set for files/tapes checkout.`, "Current state:", checkoutRestorePreferences.current);
                return;
            }
            checkoutRestorePreferences.current[refName].value = event.target.checked;
        };

        return (
            <Grid item>
                <Grid container direction="row" justifyContent="flex-start">
                    <Box>
                        <Grid item mt={-1}>
                            <Checkbox size="small" onChange={handleCheckboxChange}/>
                        </Grid>
                    </Box>
                    <Grid item>
                        <Typography style={{color: "white"}}>
                            {checkoutRestorePreferences.current?.[refName]?.text}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>);
    };

    /**
     * Returns a dialog for setting up the restore preferences during checkout (Cloud restore and Physical restore related preferences).
     * @returns {Element}
     */
    const getRestorePreferencesCheckoutDialog = () => {
        return (
            <Dialog open={checkoutDialogRestorePreferencesOpenFlag}
                    fullWidth={true}
                    PaperProps={{sx: {borderRadius: "12px", backgroundColor: "#3A3E3F"},}}>
                <DialogContent>
                    <Grid container direction="column" spacing={2}>
                        {/*Dialog header with title-question and close button*/}
                        <Grid item>
                            <Grid container direction="row" justifyContent="space-between">
                                <Grid item>
                                    <Typography style={{color: "white"}}>
                                        How do you want your files?
                                    </Typography>
                                </Grid>
                                <Box mt={-2} mr={-2} ml={2}>
                                    <Grid item>
                                        <CloseIcon
                                            onClick={handleCloseCheckoutDialog}
                                            style={{cursor: "pointer", color: "white"}}
                                            color="white"
                                            fontSize="small"
                                        />
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid item style={{marginBottom: "18px", marginTop: 0, paddingTop: 0, paddingBottom: 0}}>
                            <Grid item>
                                <Typography style={{color: "white", fontSize: 12,}}>
                                    (select at least one option)
                                </Typography>
                            </Grid>
                        </Grid>

                        {/*Grid for Cloud Restore related preferences*/}
                        <Grid item style={{border: "1px solid white", borderRadius: "12px", marginBottom: "12px",}}>
                            <Grid container direction="row" justifyContent="space-between">
                                <Grid item>
                                    <Typography style={{color: "white"}}>
                                        Cloud restore
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Box mb={2} mr={1}>
                                        <IOSSwitch
                                            checked={cloudshow}
                                            value="cloud"
                                            color="default"
                                            onChange={onChangeCloud}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            {cloudshow ? (
                                <>
                                    <Grid item mb={1}>
                                        <Typography style={{color: "white", fontSize: 12,}}>
                                            Extra options:
                                        </Typography>
                                    </Grid>
                                    {getCheckBoxPreferences("cloudRestoreSignedURL")}
                                    {getCheckBoxPreferences("cloudRestoreSpecificTimeAvailability")}
                                    {getCheckBoxPreferences("cloudRestoreTapeNamedFolder")}
                                </>) : (<></>)}
                        </Grid>

                        {/*Grid for Physical Restore related preferences*/}
                        <Grid item
                              style={{border: "1px solid white", borderRadius: "12px"}}>
                            <Grid container direction="row" justifyContent="space-between">
                                <Grid item>
                                    <Typography style={{color: "white"}}>
                                        Physical restore
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Box mb={2} mr={1}>
                                        <IOSSwitch
                                            checked={physicalshow}
                                            value="physical"
                                            color="default"
                                            onChange={onChangephysical}
                                        />
                                    </Box>
                                </Grid>
                            </Grid>
                            {physicalshow ? (
                                <>
                                    <Grid item mb={1}>
                                        <Typography style={{color: "white", fontSize: 12,}}>
                                            Extra options:
                                        </Typography>
                                    </Grid>
                                    {getCheckBoxPreferences("physicalRestoreDifferentAddress")}
                                    {getCheckBoxPreferences("physicalRestoreDiskTypeSelection")}
                                    {getCheckBoxPreferences("physicalRestoreDiskEncryption")}
                                </>) : (<></>)}
                        </Grid>
                        <Grid item>
                            <Grid container direction="row" justifyContent="space-between">
                                <Grid item>
                                    <StyledButtonOutlined
                                        variant="outlined"
                                        sx={{width: "200px", height: "60px",}}
                                        onClick={clearRestoreTypeSelection}>
                                        Clear
                                    </StyledButtonOutlined>
                                </Grid>
                                <Grid item>
                                    <StyledButtonContained
                                        onClick={handleAddress}
                                        variant="contained"
                                        sx={{width: "200px", height: "60px",}}
                                        disabled={!cloudshow && !physicalshow}>
                                        Continue
                                    </StyledButtonContained>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>);
    };

    /**
     * Internal function to generate the summary of an element selected for restoring during checkout.
     * @param element - Element to be summarized
     * @param {number} index - Index of the element in the array
     * @param {[*]}  array - The entire array of elements provided by the map function
     * @returns {Element}
     * @private
     */
    const _getRestoreElementSummary = (element, index, array) => {
        const row1DisplayKey = listFilter == 'tapeList' ? "Tape type" : "Filename";
        const row1DisplayValue = listFilter == 'tapeList' ? _getFormattedProperty(element, "media_type", "str") : _getFormattedProperty(element, "name", "str");

        const row2DisplayKey = "Volser";
        const row2DisplayValue = listFilter == 'tapeList' ? _getFormattedProperty(element, "item_volser", "str") : _getFormattedProperty(element, "volsers", "str");

        return (
            <>
                {element.selected && (
                    <Grid item>
                        <Grid container direction="row" justifyContent="space-between">
                            <Grid item>
                                <Typography style={{color: "#FFFFFF"}}>
                                    {row1DisplayKey}: <b>{row1DisplayValue}</b>
                                </Typography>
                                <Typography style={{color: "#FFFFFF"}}>
                                    {row2DisplayKey}: <b>{row2DisplayValue}</b>
                                </Typography>
                            </Grid>
                            <Grid item>
                                <StyledButtonOutlined
                                    variant="outlined"
                                    onClick={() => handleRemoveRow(element)}
                                    sx={{width: "80px", height: "40px",}}>
                                    Remove
                                </StyledButtonOutlined>
                            </Grid>
                        </Grid>
                        <hr style={{backgroundColor: "#5F5F5F", marginTop: "10px",}}/>
                    </Grid>
                )}
            </>
        );
    };

    /**
     * Returns a dialog with the restore summary during checkout.
     * @returns {Element}
     */
    function getRestoreSummaryCheckoutDialog() {
        return <Dialog open={checkoutDialogRestoreSummaryOpenFlag}
                       fullWidth={true}
                       PaperProps={{sx: {borderRadius: "12px", backgroundColor: "#3A3E3F"},}}>
            <DialogContent>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography
                            style={{
                                fontWeight: 600,
                                fontSize: "24px",
                                lineHeight: "36px",
                                color: "#FFC540",
                            }}>
                            Checkout
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography style={{color: "#FFFFFF"}}>
                            <b>{addedRows.length} item{addedRows.length !== 1 ? "s" : ""}</b>
                        </Typography>
                        <hr style={{backgroundColor: "#5F5F5F", marginTop: "10px"}}/>
                    </Grid>
                    <Grid item>
                        <Typography style={{color: "#FFFFFF"}}>
                            <b>Name</b>
                        </Typography>
                    </Grid>
                    {addedRows.map(_getRestoreElementSummary)}
                    <Grid item>
                        <Grid container direction="row" justifyContent="space-between">
                            <Grid item>
                                <StyledButtonOutlined
                                    variant="outlined"
                                    onClick={handleClickCancel}
                                    sx={{width: "200px", height: "60px",}}>
                                    {/*Cancel*/}
                                    Back
                                </StyledButtonOutlined>
                            </Grid>
                            <StyledButtonContained
                                variant="contained"
                                sx={{width: "200px", height: "60px", borderRadius: "30px",}}
                                onClick={handleOpenFormDialog}>
                                Continue
                            </StyledButtonContained>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>;
    }

    return (
        <>
            <ThemeProvider theme={restoreTheme}>
                {getRestoreSummaryCheckoutDialog()}
                {getRestorePreferencesCheckoutDialog()}
                <Dialog
                    open={addressDialog}
                    fullWidth={true}
                    PaperProps={{
                        sx: {borderRadius: "12px", backgroundColor: "#3A3E3F"},
                    }}
                >
                    <DialogContent>
                        <Grid container direction="column" spacing={2}>
                            <Grid item>
                                <Grid container direction="row" justifyContent="space-between">
                                    <Grid item>
                                        <Typography
                                            style={{
                                                color: "white",
                                                fontSize: "24px",
                                                fontWeight: 400,
                                            }}
                                        >
                                            Select Address
                                        </Typography>
                                    </Grid>
                                    <Box mt={-2} mr={-2} ml={2}>
                                        <Grid item>
                                            <CloseIcon
                                                onClick={handleCloseAddress}
                                                style={{cursor: "pointer", color: "white"}}
                                                color="white"
                                                fontSize="small"
                                            />
                                        </Grid>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid item className={classes.selectLabel}>
                                <TextField
                                    fullWidth
                                    label="Enter Zip Code"
                                    id="fullWidth"
                                    sx={{label: {color: "white"}}}
                                />
                            </Grid>
                            <Grid item className={classes.selectLabel}>
                                <TextField
                                    fullWidth
                                    label="Enter Mobile Number"
                                    id="fullWidth"
                                    sx={{label: {color: "white"}}}
                                />
                            </Grid>
                            <Grid item className={classes.selectLabel}>
                                <TextField
                                    fullWidth
                                    label="Address line 1"
                                    id="fullWidth"
                                    sx={{label: {color: "white"}}}
                                />
                            </Grid>
                            <Grid item className={classes.selectLabel}>
                                <TextField
                                    fullWidth
                                    label="Address line 2"
                                    id="fullWidth"
                                    sx={{label: {color: "white"}}}
                                />
                            </Grid>
                            <Grid item>
                                <Grid container direction="row" justifyContent="flex-end">
                                    <Grid item>
                                        <StyledButtonContained
                                            variant="contained"
                                            onClick={() => {
                                                handleAddress();
                                            }}
                                        >
                                            Save & Continue
                                        </StyledButtonContained>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>

                {/* // Restore Form Component */}
                <RestoreForm
                    isFormOpen={isFormOpen}
                    handleCloseFormDialog={handleCloseFormDialog}
                    cloudShow={cloudshow}
                    physicalShow={physicalshow}
                    addedRows={addedRows}
                    handleCloseCheckoutDialog={handleCloseCheckoutDialog}
                    handleClickcancel={handleClickCancel}
                    clearAddedRows={clearAddedRows}
                    showReset={showReset}
                    listFilter={listFilter}
                    checkoutRestorePreferences={checkoutRestorePreferences.current}
                />
                {/* // Restore Form Component */}

                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                        paddingTop: 4,
                        paddingLeft: 4,
                    }}
                    className="hideElement"
                >
                    <img
                        src="/assets/img/restore.png"
                        alt=""
                        style={{filter: "brightness(10)"}}
                    />
                </Box>

                {/* Header */}
                <Grid
                    container
                    spacing={2}
                    paddingBottom={6}
                    sx={{paddingX: {xs: 0.5, md: 4}}}
                    className="hideElement"
                >
                    <Grid item md={12}>
                        <Typography
                            variant="h5"
                            mt={4}
                            sx={{
                                fontFamily: "Lato",
                                fontSize: "28px",
                                fontWeight: 700,
                                lineHeight: "34px",
                                letterSpacing: "0em",
                                textAlign: "left",
                                color: "#FFFFFF",
                            }}
                        >
                            Restore Files
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Divider
                            sx={{border: "1px solid #EAEAEA", width: "100%"}}
                            orientation="horizontal"
                        />
                    </Grid>
                </Grid>

                <Grid
                    container
                    px={8}
                    spacing={2}
                    paddingBottom={6}
                    sx={{paddingX: {xs: 0.5, md: 4}}}
                    mb={bottomDrawerHeight}
                >
                    <Grid item xs={12}>
                        {/* Overview Filters */}
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            spacing={4}
                        >
                            <Grid item md={12}>
                                <Typography
                                    sx={{
                                        textAlign: "left",
                                        color: "#FFFFFF",
                                        fontFamily: "Poppins",
                                        fontSize: "18px",
                                        lineHeight: "27px",
                                        fontWeight: 700,
                                    }}
                                    style={{fontWeight: 700, fontSize: '20px', color: "#fff"}}
                                >
                                    Overview
                                </Typography>
                            </Grid>
                            {/*{docTypeOverview.map((item, _index) => (*/}
                            {/*    <Grid item md key={_index} minWidth={"20%"}>*/}
                            {/*        <Card*/}
                            {/*            sx={{*/}
                            {/*                backgroundColor: isFileTypeFilterSelected(item.docType)*/}
                            {/*                    ? "#FFC540"*/}
                            {/*                    : "#3A3E3F",*/}
                            {/*                border: "1px solid #C7CCD0",*/}
                            {/*                borderRadius: "12px",*/}
                            {/*                height: "120px",*/}
                            {/*                textAlign: "left",*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            <CardHeader*/}
                            {/*                sx={{*/}
                            {/*                    pb: 0,*/}
                            {/*                    "& .MuiCardHeader-title": {*/}
                            {/*                        fontFamily: "Poppins",*/}
                            {/*                        fontWeight: 700,*/}
                            {/*                        fontSize: 18,*/}
                            {/*                        color: isFileTypeFilterSelected(item.docType)*/}
                            {/*                            ? "#3A3E3F"*/}
                            {/*                            : "#FFFFFF",*/}
                            {/*                    },*/}
                            {/*                    "& .MuiCardHeader-subheader": {*/}
                            {/*                        fontFamily: "Poppins",*/}
                            {/*                        fontWeight: 400,*/}
                            {/*                        fontSize: 12,*/}
                            {/*                        color: isFileTypeFilterSelected(item.docType)*/}
                            {/*                            ? "#3A3E3F"*/}
                            {/*                            : "#C7CCD0",*/}
                            {/*                    },*/}
                            {/*                }}*/}
                            {/*                title={item.docType}*/}
                            {/*                subheader={"2,164 items"}*/}
                            {/*                avatar={*/}
                            {/*                    <CustomFileTypeIcon*/}
                            {/*                        color={*/}
                            {/*                            isFileTypeFilterSelected(item.docType)*/}
                            {/*                                ? "#3A3E3F"*/}
                            {/*                                : "#FFC540"*/}
                            {/*                        }*/}
                            {/*                        path={item.iconPath}*/}
                            {/*                    />*/}
                            {/*                }*/}
                            {/*                // action={*/}
                            {/*                //   <IconButton>*/}
                            {/*                //     <InfoOutlined*/}
                            {/*                //       sx={{*/}
                            {/*                //         color: isFileTypeFilterSelected(item.docType)*/}
                            {/*                //           ? "#3A3E3F"*/}
                            {/*                //           : "#FFC540",*/}
                            {/*                //       }}*/}
                            {/*                //     />*/}
                            {/*                //   </IconButton>*/}
                            {/*                // }*/}
                            {/*            />*/}
                            {/*            <CardContent>*/}
                            {/*                <LinearProgress*/}
                            {/*                    variant="determinate"*/}
                            {/*                    value={70}*/}
                            {/*                    sx={{*/}
                            {/*                        backgroundColor: "#3A3E3F",*/}
                            {/*                        height: 10,*/}
                            {/*                        borderRadius: 8,*/}
                            {/*                        border: isFileTypeFilterSelected(item.docType)*/}
                            {/*                            ? "1px solid #3A3E3F"*/}
                            {/*                            : "1px solid #FFC540",*/}
                            {/*                        "& .MuiLinearProgress-bar": {*/}
                            {/*                            backgroundColor: "#FFC540",*/}
                            {/*                        },*/}
                            {/*                    }}*/}
                            {/*                />*/}
                            {/*                <Typography*/}
                            {/*                    variant="h5"*/}
                            {/*                    component="div"*/}
                            {/*                    sx={{*/}
                            {/*                        fontSize: "12px",*/}
                            {/*                        lineHeight: "18px",*/}
                            {/*                        fontFamily: "Poppins",*/}
                            {/*                        fontWeight: 700,*/}
                            {/*                        color: isFileTypeFilterSelected(item.docType)*/}
                            {/*                            ? "#3A3E3F"*/}
                            {/*                            : "#C7CCD0",*/}
                            {/*                        mt: 1,*/}
                            {/*                    }}*/}
                            {/*                >*/}
                            {/*                    Total {item.size}*/}
                            {/*                </Typography>*/}
                            {/*            </CardContent>*/}
                            {/*        </Card>*/}
                            {/*    </Grid>*/}
                            {/*))}*/}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TableContainer
                            sx={{
                                mt: 8,
                                maxWidth: "100%",
                                border: "1px solid #C7CCD0",
                                borderRadius: "12px 12px 0px 0px",
                                minWidth: "55%",
                            }}
                        >
                            <Toolbar
                                sx={{
                                    flexGrow: 1,
                                    display: "flex",
                                    justifyContent: "space-between",
                                    my: 1,
                                }}
                            >
                                <Grid container spacing={4} my={2}>
                                    <Grid item md={12}>
                                        {/* Toggle */}
                                        <Paper
                                            elevation={1}
                                            sx={{
                                                border: "1px solid #FFFFFF",
                                                height: "fit-content",
                                                width: "fit-content",
                                                display: "flex",
                                                backgroundColor: PrimaryColor,
                                                flexWrap: "wrap",
                                            }}
                                        >
                                            <ToggleButtonGroup
                                                color="primary"
                                                sx={{
                                                    "& .MuiToggleButtonGroup-grouped": {
                                                        margin: 0.5,
                                                        border: 0,
                                                        "&.Mui-selected": {color: "#3E3A3F",},
                                                    },
                                                }}
                                                exclusive
                                                aria-label="Platform"
                                                value={listFilter}
                                                onChange={(e) => listFilterChange(e)}
                                            >
                                                <ToggleButton className="display-mode" value="tapeList">
                                                    Show Tapes
                                                </ToggleButton>
                                                <ToggleButton className="display-mode" value="itemList">
                                                    Show Files
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                            {listFilter == "itemList" && SEISMIC_CUSTOMER_ARKBRIDGE_IDS.includes(Number(userDetails.user.arkbridge_customer_id)) ? filesTypeSelector() : ""}
                                        </Paper>
                                    </Grid>

                                    <Grid item md={12}>
                                        {/* Toggle */}
                                        <Paper
                                            elevation={1}
                                            sx={{
                                                border: "1px solid #FFFFFF",
                                                height: "fit-content",
                                                width: "fit-content",
                                                display: "flex",
                                                backgroundColor: PrimaryColor,
                                                flexWrap: "wrap",
                                            }}
                                        >
                                            <ToggleButtonGroup
                                                color="primary"
                                                sx={{
                                                    "& .MuiToggleButtonGroup-grouped": {
                                                        margin: 0.5,
                                                        border: 0,
                                                        "&.Mui-selected": {
                                                            color: "#3E3A3F",
                                                        },
                                                    },
                                                }}
                                                value={displayMode}
                                                exclusive
                                                onChange={handleDisplayModeChange}
                                                aria-label="Platform"
                                            >
                                                <ToggleButton className="display-mode" value="All">
                                                    Show All
                                                </ToggleButton>
                                                <ToggleButton className="display-mode" value="Selected">
                                                    {addedRows.length !== 0 ? addedRows.length : ""}{" "}
                                                    Selected Only
                                                </ToggleButton>
                                            </ToggleButtonGroup>
                                        </Paper>
                                    </Grid>
                                    <Grid item md={6}>
                                        {/* Seach bar */}
                                        <InputBase
                                            id="search-restores"
                                            className="search-restores"
                                            type="text"
                                            placeholder="Search"
                                            value={searchQuery}
                                            onChange={handleSearchQuery}
                                            startAdornment={
                                                <InputAdornment
                                                    position="start"
                                                    sx={{color: "#A6AEC1", height: 24, width: 24}}
                                                >
                                                    <SearchIcon/>
                                                </InputAdornment>
                                            }
                                            endAdornment={
                                                searchQuery !== "" && (
                                                    <InputAdornment
                                                        position="end"
                                                        sx={{color: "#A6AEC1", height: 24, width: 24}}
                                                    >
                                                        <IconButton onClick={() => {
                                                            clearSearch()
                                                        }}>
                                                            <Cancel sx={{color: "#FFFFFF"}}/>
                                                        </IconButton>
                                                    </InputAdornment>
                                                )
                                            }
                                            sx={{
                                                fontSize: "16px",
                                                fontWeight: "400",
                                                lineHeight: "15px",
                                                letterSpacing: "0em",
                                                textAlign: "left",
                                                width: "100%",
                                                height: "60px",
                                                backgroundColor: PrimaryColor,
                                                borderRadius: "12px",
                                                px: "20px",
                                                border: "1px solid #C7CCD0",
                                                outline: "none",
                                                color: "#FFFFFF",
                                            }}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={6}
                                        display={"flex"}
                                        justifyContent={"flex-end"}
                                        spacing={4}
                                    >
                                        {/* Filter Popup */}
                                        {/*<Badge badgeContent={filterCount} color="primary">*/}
                                        {/*    <Button*/}
                                        {/*        variant="outlined"*/}
                                        {/*        startIcon={<Tune sx={{color: "#FFFFFF"}}/>}*/}
                                        {/*        onClick={handleOpenFilterPopup}*/}
                                        {/*        style={{*/}
                                        {/*            width: "160px",*/}
                                        {/*            border: "1px solid #C7CCD0",*/}
                                        {/*            borderRadius: "12px",*/}
                                        {/*            color: "#FFFFFF",*/}
                                        {/*            height: "60px",*/}
                                        {/*            textTransform: "none",*/}
                                        {/*            fontWeight: 700,*/}
                                        {/*        }}*/}
                                        {/*    >*/}
                                        {/*        Filters*/}
                                        {/*    </Button>*/}
                                        {/*</Badge>*/}
                                        <RestoreFilters
                                            open={filterPopupOpen}
                                            onClose={handleCloseFilterPopup}
                                            fileTypeFilter={fileTypeFilter}
                                            setFileTypeFilter={setFileTypeFilter}
                                            onFilterChange={(count) => setFilterCount(count)}
                                        ></RestoreFilters>
                                    </Grid>
                                </Grid>
                            </Toolbar>

                            {/*Table in which the components of tapes list or files list will be rendered*/}
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {tableColumns
                                            ? (tableColumns.map((column, colIndex) => (
                                                <TableCell className={classes.columnHeader}>
                                                    <Typography sx={{marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700"}}>
                                                        {column.name}
                                                    </Typography>
                                                </TableCell>
                                            )))
                                            : (
                                                <TableCell colSpan={2} align="centre">
                                                    <div style={{textAlign: "center", width: "50%", margin: "0", left: "50%", alignItems: "center", color: "#fff"}}>Data not Found</div>
                                                </TableCell>
                                            )
                                        }
                                        {tableColumns
                                            ? (
                                                <TableCell className={classes.columnHeader} align="centre">
                                                    <Typography sx={{marginBottom: 0, color: "#FFFFFF", fontSize: 14, fontWeight: "700", alignItems: "center", textAlign: "center"}}>
                                                        Action
                                                    </Typography>
                                                </TableCell>
                                            )
                                            : ""}
                                    </TableRow>
                                </TableHead>

                                <TableBody sx={{"& > *": {backgroundColor: "#3A3E3F", borderRadius: "8px",},}}>
                                    {restoreData.length > 0 ? (displayMode === "All" ? restoreData : addedRows)
                                            .map((row, rowIndex) => (
                                                <TableRow key={rowIndex} sx={{"& > *": {border: "unset",},}}>
                                                    {tableColumns ? (tableColumns.map((column, colIndex) => dynamicTableCell(row, rowIndex, column, colIndex))) : noDataCell()}

                                                    {/*If tableColumns ensure and Add/Remove button is added at the end of each row */}
                                                    {tableColumns ? dynamicAddRemoveButtonCell(row) : ""}
                                                </TableRow>
                                            )) :
                                        <TableRow>
                                            <TableCell colSpan={2} align="centre">
                                                <div style={{textAlign: "center", width: "50%", margin: "0", left: "50%", alignItems: "center", color: "#fff"}}>Data not Found</div>
                                            </TableCell>
                                        </TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    {/* Pagination */}
                    {displayMode != 'Selected' ?
                        <>
                            <Grid item xs={12}>
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="space-between"
                                    padding={2}
                                    style={{
                                        width: "100%",
                                        border: "1px solid #C7CCD0",
                                        minWidth: "55%",
                                    }}>
                                    <Grid item ml={2}>
                                        <Typography
                                            sx={{
                                                fontFamily: "Poppins",
                                                fontSize: "16px",
                                                lineHeight: "24px",
                                                fontWeight: 400,
                                                color: "#FFFFFF",
                                            }}>
                                            {`Showing ${tapeCount ? startIndex : 0}-${endIndex} of ${tapeCount} Items`}
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <Pagination
                                            component="div"
                                            rowsPerPage={10}
                                            count={totalPages}
                                            page={currentPage}
                                            onChange={handlePageChange}
                                            sx={{
                                                "& .MuiPaginationItem-root": {
                                                    width: "30px",
                                                    height: "30px",
                                                    border: "1px solid #FFFFFF",
                                                    background: "#3A3E3F",
                                                    color: "#FFFFFF",
                                                    margin: "0 4px",
                                                    "&.Mui-selected": {
                                                        background: "#FFC540",
                                                        color: "#3A3E3F",
                                                    },
                                                },
                                                "& .MuiPaginationItem-page.Mui-selected:hover": {
                                                    background: "#FFC540",
                                                    color: "#3A3E3F",
                                                },
                                                "& .MuiPaginationItem-page:hover": {
                                                    background: "#FFC540",
                                                    color: "#3A3E3F",
                                                },
                                                "& .MuiPaginationItem-previous, & .MuiPaginationItem-next": {
                                                    width: "100px",
                                                    height: "40px",
                                                    borderRadius: "8px",
                                                    border: "1px solid #FFFFFF",
                                                    color: "#FFFFFF",
                                                    background: "#3A3E3F",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                },
                                                "& .MuiPaginationItem-previous:hover, & .MuiPaginationItem-next:hover": {
                                                    background: "#FFC540",
                                                    color: "#3A3E3F",
                                                },
                                            }}
                                            renderItem={(item) => <PaginationItem {...item} />}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid></> : <></>
                    }
                </Grid>

                {/* Bottom Drawer which opens when items selected */}
                <Drawer
                    variant={"persistent"}
                    anchor={"bottom"}
                    open={addedRows.length > 0}
                    sx={{
                        "& .MuiDrawer-paper": {
                            backgroundColor: "#3A3E3F",
                            boxShadow: "0px -7px 11px 0px #00000029",
                            height: bottomDrawerHeight,
                        },
                    }}>
                    <Box gap={4} display={"flex"} justifyContent={"right"} margin={1}>
                        <Typography
                            sx={{
                                textAlign: "left",
                                fontSize: 18,
                                color: "#FFFFFF",
                                fontWeight: 400,
                                mt: 2,
                            }}>
                            {addedRows.length !== 0 ? addedRows.length + " file(s) added" : ""}
                        </Typography>
                        {/* Clear Button */}
                        <Button
                            variant="outlined"
                            onClick={clearAddedRows}
                            sx={{
                                width: "220px",
                                height: "60px",
                                borderRadius: "30px",
                                color: "#FFFFFF",
                                textTransform: "none",
                                border: "1px solid #FFFFFF",
                                "&:hover": {
                                    backgroundColor: "#C7CCD0",
                                },
                            }}>
                            Clear
                        </Button>
                        {/* Checkout Button */}
                        <StyledButtonContained
                            variant="contained"
                            onClick={handleOpenCheckoutDialog}
                            sx={{
                                width: "220px",
                                height: "60px",
                            }}>
                            Checkout
                        </StyledButtonContained>
                    </Box>
                </Drawer>
            </ThemeProvider>
            <TapeDetails open={openTapeDetails} closePopup={() => {
                setOpenTapeDetails(false)
            }} tapeId={selectedData}></TapeDetails>
        </>
    );
};

const sizes = ["Mb", "Tb", "Pb"];

const RestoreFilters = ({
                            open,
                            onClose,
                            fileTypeFilter,
                            setFileTypeFilter,
                            onFilterChange,
                        }) => {

    const handleClose = () => {
        onClose();
    };

    const handleFileTypeFilter = (value) => {
        const selectedIndex = fileTypeFilter.indexOf(value);
        let newFileTypeFilter = [...fileTypeFilter];

        if (selectedIndex === -1) newFileTypeFilter.push(value)
        else newFileTypeFilter.splice(selectedIndex, 1)

        setFileTypeFilter(newFileTypeFilter);
    };

    const [startDateFilter, setStartDateFilter] = useState(null);
    const [endDateFilter, setEndDateFilter] = useState(null);

    const [minSize, setMinSize] = useState("");
    const [maxSize, setMaxSize] = useState("");

    useEffect(() => {
        let filterCount = fileTypeFilter.length;
        filterCount += startDateFilter && endDateFilter ? 1 : 0;
        filterCount += minSize && maxSize ? 1 : 0;

        onFilterChange(filterCount);
    }, [startDateFilter, endDateFilter, minSize, maxSize, fileTypeFilter.length]);

    const handleFilterClear = () => {
        setFileTypeFilter([]);
        setEndDateFilter(null);
        setStartDateFilter(null);
        setMaxSize("");
        setMinSize("");
    };

    return (
        <Drawer
            variant="persistent"
            anchor="right"
            open={open}
            sx={{
                "& .MuiDrawer-paper": {
                    top: "25%",
                    borderRadius: 2,
                    p: 2,
                    width: 400,
                    height: "70%",
                    overflowY: "auto",
                    backgroundColor: "#4E5152",
                    boxShadow: "-4px 4px 4px 0px #00000029",
                },
            }}>
            <Grid container px={2} spacing={2} style={{"marginBottom": '140px'}}>
                <Grid item xs={12}>
                    {/* Title area */}
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "16px",
                            alignItems: "center",
                        }}>
                        <Typography variant="h6" sx={{color: "#FFFFFF"}} noWrap>
                            Filters
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <Close sx={{color: "#FFFFFF"}}/>
                        </IconButton>
                    </Box>
                </Grid>

                <Grid item xs={12}>
                    {/* File type filters */}
                    <Typography sx={{color: "#FFFFFF", textAlign: "left"}}>
                        File types:
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Grid container spacing={2}>
                        {docTypeOverview.map((type) => (
                            <Grid item>
                                <ToggleButton
                                    className="file-type"
                                    value={type.docType}
                                    selected={fileTypeFilter.includes(type.docType)}
                                    onClick={() => handleFileTypeFilter(type.docType)}
                                    variant="outlined">
                                    {type.docType}
                                </ToggleButton>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    {/* Size filters */}
                    <Typography sx={{color: "#FFFFFF", textAlign: "left"}}>
                        Size:
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Stack direction={"row"}>
                        <TextField
                            className="number-input"
                            variant="outlined"
                            label="min-size"
                            value={minSize}
                            onChange={(e) => setMinSize(e.target.value)}
                            InputLabelProps={{shrink: true,}}/>
                        <TextField
                            fullWidth
                            select
                            variant="outlined"
                            value="Mb"
                            className="number-input-uom">
                            {sizes.map((size, sizeIndex) => (
                                <MenuItem key={sizeIndex} value={size}>
                                    {size}
                                </MenuItem>
                            ))}
                        </TextField>
                        <Typography
                            px={2}
                            sx={{
                                color: "#FFFFFF",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                            }}>
                            -
                        </Typography>
                        <TextField
                            className="number-input"
                            variant="outlined"
                            label="max-size"
                            value={maxSize}
                            onChange={(e) => setMaxSize(e.target.value)}
                            InputLabelProps={{shrink: true,}}/>
                        <TextField
                            select
                            variant="outlined"
                            value="Mb"
                            className="number-input-uom">
                            {sizes.map((size, sizeIndex) => (
                                <MenuItem key={sizeIndex} value={size}>
                                    {size}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    {/* Date filters */}
                    <Typography sx={{color: "#FFFFFF", textAlign: "left"}}>
                        Creation Date:
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Stack direction={"row"} gap={4}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DatePicker
                                label="Start Date"
                                disableFuture
                                className="date-input"
                                type="date"
                                value={startDateFilter}
                                onChange={(newValue) => setStartDateFilter(newValue)}
                                variant="outlined"/>
                            <DatePicker
                                label="End Date"
                                disableFuture
                                className="date-input"
                                type="date"
                                value={endDateFilter}
                                onChange={(newValue) => setEndDateFilter(newValue)}
                                variant="outlined"/>
                        </LocalizationProvider>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Divider
                        orientation="horizontal"
                        sx={{backgroundColor: "#FFFFFF", width: "100%", my: 2}}
                    ></Divider>
                </Grid>

                <Grid item xs={12}>
                    <Box sx={{display: "flex", justifyContent: "space-between"}}>
                        {/* Clear Button */}
                        <StyledButtonContained
                            onClick={handleFilterClear}
                            variant="contained"
                            size="medium"
                            color="secondary">
                            Clear
                        </StyledButtonContained>
                        {/* Apply Button */}
                        <StyledButtonContained
                            variant="contained"
                            size="medium"
                            color="primary">
                            Apply
                        </StyledButtonContained>
                    </Box>
                </Grid>
            </Grid>
        </Drawer>
    );
};
