import React, {useEffect, useState} from 'react';
import {Box, Dialog, DialogContent, Grid, Typography} from '@mui/material';
import axios from 'axios';
//import { env } from '../../env';
// import { StyledButtonContained } from '../../views/Admin/AccountCreation/accountCreation';
import {StyledButtonContained,} from "../Admin/AccountCreation/accountCreation";
import {formats} from "./../../global";
import {TAPES_ARRAY} from '../../utils/constants';

const formatBytes = (bytes, decimals = 0) => {
    if (!+bytes) return '0'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}
export const TapeDetails = ({open, closePopup, tapeId, fromCompany}) => {
    const [tapeData, setTapeData] = useState("")
    const [tapeImageData, setTapeImageData] = useState("")
    const getTapeImage = (media_type) => {
        let _data = TAPES_ARRAY.filter(e => e.fileName.includes(media_type));
        return _data.length && _data[0].filePath
    }
    useEffect(() => {
        tapeId && getTapeDetails(tapeId) && getTapeImageDetails(tapeId);

    }, [tapeId])
    const getTapeDetails = async (event) => {
        let payload = {
            type: "man_item_get",
            request: {
                item_id: tapeId,
                type: "man_item_get",
            },
        };
        axios
            .post(formats, payload)
            .then((response) => {
                if (response?.status == 200) {
                    if (!fromCompany) {
                        setTapeData(Object.values(response.data.result)[0]?.data)
                    } else {
                        setTapeData(response?.data?.result?.data)
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }

    const getTapeImageDetails = async (event) => {
        let payload = {
            type: "man_item_image_get",
            request: {
                item_id: tapeId,
                type: "man_item_image_get",
            },
        };
        axios
            .post(formats, payload)
            .then((response) => {
                if (response?.status == 200) {
                    debugger;
                    setTapeImageData(Object.values(response.data.result)[0]?.data[0])
                }
            })
            .catch((error) => {
                console.log(error);
            });

    }
    const handleClose = () => {
        closePopup();
    };

    return (<>
            <Dialog open={open} fullWidth={true}
                    sx={{
                        '& .MuiDialog-paper': {
                            borderRadius: '8px',
                            minWidth: '1100px'
                        },
                    }}>
                <DialogContent sx={{p: 4, backgroundColor: '#3A3E3F', color: '#FFFFFF', paddingRight: '0px'}}>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 2, alignItems: 'center'}}>
                        {/* <Typography sx={{ color: '#FFC540', fontSize: 18 }}>Tape Details</Typography> */}

                    </Box>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                <Grid item style={{paddingTop: '0px'}}>
                                    <img style={{height: '500px'}} src={tapeImageData?.presigned_url}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} xs={6}>

                            <Grid item xs={6}>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item style={{minWidth: '200px'}}>
                                        <Typography align='left'>Bar Code </Typography>
                                        <Typography>{tapeData?.item_volser}</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item style={{minWidth: '200px'}}>
                                        <Typography align='left'> Alt Bar Code </Typography>
                                        <Typography>{tapeData?.other_volsers?.join(", ")}</Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item style={{minWidth: '200px'}}>
                                        <Typography align='left'>TapeArk Bar Code </Typography>
                                        <Typography>{tapeData?.item_barcode}</Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item style={{minWidth: '200px'}}>
                                        <Typography align='left'>Case Bar Code </Typography>
                                        <Typography>{tapeData?.item_volser}</Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item style={{minWidth: '200px'}}>
                                        <Typography align='left'>TapeArk Case Bar Code </Typography>
                                        <Typography>{tapeData?.box_barcode}</Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item style={{minWidth: '200px'}}>
                                        <Typography align='left'>Format</Typography>
                                        <Typography></Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item style={{minWidth: '200px'}}>
                                        <Typography align='left'>Server/Site</Typography>
                                        <Typography>{}</Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item style={{minWidth: '200px'}}>
                                        <Typography align='left'>Media Type </Typography>
                                        <Typography>{tapeData?.media_type}</Typography>

                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item style={{minWidth: '200px'}}>
                                        <Typography align='left'>Manufacturer</Typography>
                                        <Typography>{tapeData?.brand}</Typography>

                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item style={{minWidth: '200px'}}>
                                        <Typography align='left'>Brand</Typography>
                                        <Typography>{tapeData?.brand}</Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item style={{minWidth: '200px'}}>
                                        <Typography align='left'>Tape Ark ID </Typography>
                                        <Typography>{tapeData?.item_id}</Typography>

                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item style={{minWidth: '200px'}}>
                                        <Typography align='left'>Catalogued Date </Typography>
                                        <Typography>{tapeData?.created}</Typography>

                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item style={{minWidth: '200px'}}>
                                        <Typography align='left'>Retention Date </Typography>
                                        <Typography>{}</Typography>

                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item style={{minWidth: '200px'}}>
                                        <Typography align='left'>Catalogued Used</Typography>
                                        <Typography></Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item style={{minWidth: '200px'}}>
                                        <Typography align='left'>Maximum Capacity</Typography>
                                        <Typography>{formatBytes(tapeData?.native_capacity_mb)}</Typography>
                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container direction='row' justifyContent='flex-start' spacing={2}>
                                    <Grid item style={{minWidth: '200px'}}>
                                        <Typography align='left'>Operator Notes</Typography>
                                        <Typography>{tapeData?.operator_notes}</Typography>

                                    </Grid>

                                </Grid>
                            </Grid>
                            <Grid item xs={6}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                    </Grid>
                                    <Grid item xs={6}>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 2}}>
                        <StyledButtonContained variant='contained'
                                               onClick={handleClose}
                                               sx={{
                                                   width: '180px',
                                                   height: '60px',
                                                   marginLeft: 'auto',
                                                   marginRight: "50px"
                                               }}>
                            Close
                        </StyledButtonContained>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}
